<template>
  <b10-base>
    <v-checkbox
      v-model="form.es_desplazamiento"
      label="Es desplazamiento"
    />
    <v-text-field
      v-model.number="form.latitud"
      label="Latitud"
      disabled
    />
    <v-text-field
      v-model.number="form.longitud"
      label="Longitud"
      disabled
    />
    <v-btn
      v-show="form.latitud && form.longitud"
      block
      color="secondary"
      dark
      @click.stop="clickIrMapa(form.latitud, form.longitud)"
    >
      <v-icon
        left
      >
        {{ $vuetify.icons.values.mapLocation }}
      </v-icon> Localización
    </v-btn>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import { getLatLongURL } from '@/utils/maps'

export default {
  mixins: [formMixin],
  props: {
    geolocalizacion: {
      type: Object,
      default: () => {},
    },
    hasPermIntroducirOtrosTecnicos: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      form: {
        es_desplazamiento: false,
        latitud: null,
        longitud: null,
      },
    }
  },
  watch: {
    geolocalizacion: {
      handler: function (newValue) {
        if (newValue) {
          this.$set(this.form, 'latitud', newValue.lat)
          this.$set(this.form, 'longitud', newValue.lng)
        }
      },
      deep: true,
    },
  },
  async created () {
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    clickIrMapa (lat, lng) {
      window.open(getLatLongURL(lat, lng), '_blank')
    },
  }
}
</script>
